@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.container {
  padding: 90px 0;

  @media (min-width: $desktop-min-breakpoint) {
    padding: 180px 0 180px 62px;
  }
}

.header {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: rem-calc(36);
  line-height: 1.25;

  @media (max-width: $mobile-max-breakpoint) {
    font-size: rem-calc(24);
  }
}

.text {
  margin-bottom: 64px;
  font-weight: 400;
  font-size: rem-calc(24);
  line-height: 1.25;

  @media (max-width: $mobile-max-breakpoint) {
    margin-bottom: 32px;
    font-size: rem-calc(20);
  }
}

.button {
  padding: 16px 24px;
  font-size: rem-calc(24);
  line-height: 125%;
  width: 255px;

  @media (max-width: $mobile-max-breakpoint) {
    margin-bottom: 64px;
    padding: 10px 24px;
    width: 100%;
    font-size: rem-calc(16);
  }
}

.containerImage {
  margin-left: 58px;
  max-width: 528px;

  @media (max-width: $desktop-max-breakpoint) {
    max-width: 428px;
  }

  @media (max-width: $tablet-max-breakpoint) {
    max-width: 328px;
  }

  @media (max-width: $mobile-max-breakpoint) {
    margin-left: 0;
    max-width: 500px;
  }
}

.textBlock {
  margin-top: 45px;

  @media (max-width: $desktop-max-breakpoint) {
    margin-top: 32px;
  }

  @media (max-width: $tablet-max-breakpoint) {
    margin-top: 0;
  }
}
